@import 'config';

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: $fontLato;
    background: $creamWhite;
}

.page {
    background: $darkPurple;
}

.btn-purple {
    color: $white;
    background: $lightPurple;

    &:hover {
        color: $white;
        background: darken($lightPurple, 3%);
    }
}