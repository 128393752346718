@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
*{box-sizing:border-box}html{scroll-behavior:smooth}body{margin:0;font-family:"Lato","Arial",sans-serif;background:#eee}.page{background:#271747}.btn-purple{color:#fff;background:#3a2368}.btn-purple:hover{color:#fff;background:#341f5d}

.navigation{margin-left:auto}.navigation__link{color:#fff;padding:4px 16px}.navigation__link--active{font-weight:bold}.navigation__link:hover{color:#e594ab;text-decoration:none}.custom-toggler .navbar-toggler-icon{background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")}.custom-toggler.navbar-toggler{border-color:#eee}

.home{background:#271747}.home__hero{display:block;scroll-snap-align:start;height:100vh;overflow:hidden;color:#fff;background-image:url(/static/media/bg2.8b27b8b0.svg);background-position:center;background-repeat:no-repeat;background-size:cover;padding-top:74px}.hero__greeting{margin:0;font-size:32pt;font-weight:bold}.hero__myname{font-size:40pt;font-weight:bold;border-bottom:6px solid #e594ab}.hero__caption{font-size:20pt}.hero__caption>span{display:block;font-weight:bold}.hero__caption>span{display:block;font-weight:bold}.hero__scrolldown{display:inline-block;color:#fff;font-size:14pt}.hero__scrolldown:hover{color:#e594ab;text-decoration:none}.home__work{display:block;background:#eee;color:#271747;padding-top:80px}.work__title{margin:0;text-align:center;font-size:48pt;font-weight:bold;text-transform:uppercase}.work__header{font-size:32pt;padding:32px 0px 16px}.work__description{margin:0;font-size:16pt;line-height:28pt}@media (min-width: 992px){.home__hero{padding-top:64px}.hero__greeting{font-size:58pt}.hero__myname{font-size:96pt}.hero__caption{font-size:24pt}.hero__scrolldown{font-size:16pt}.work__title{padding-bottom:16px}}@media (min-width: 1200px){.home__hero{padding-top:64px}.hero__greeting{font-size:58pt}.hero__myname{font-size:104pt}.hero__caption{font-size:26pt}.hero__scrolldown{font-size:18pt}.work__title{padding-bottom:32px}}

.resume__content{display:block;color:#fff;padding-top:96px;background:#271747;background-position:bottom;background-repeat:no-repeat;background-size:cover}.experiences__title{color:#eee;font-weight:bold;font-size:28pt}.experiences__download{display:inline-block;color:#eee;font-weight:bold;font-size:14pt}.experiences__download:hover{color:#e594ab;text-decoration:none}.experiences__skills{margin-top:24px;color:#eee;font-weight:bold;font-size:22pt}.experiences__experience{margin-top:16px;margin-bottom:-16px;color:#eee;font-weight:bold;font-size:22pt}@media (min-width: 992px){.resume__content{padding-top:160px}}@media (min-width: 992px){.experiences__title{font-size:36pt}}

.css-1psltl0{margin-top:-1em !important}.css-vcfpfv{background:#e594ab !important;outline:8px solid #3a2368 !important;-webkit-transform:rotate(0) !important;transform:rotate(0) !important;border-radius:5px;-moz-outline-radius:25px}.css-692fgp{color:#e594ab !important;font-size:12pt !important;font-weight:bold !important;background:transparent !important;padding:0.25em 0em !important}.css-1b3lipl{max-width:150%}.css-1jv1kel>h3{font-size:14pt !important}.css-1jv1kel>h4{font-size:22pt !important}.css-7mkrez{margin-top:-20px !important;font-size:14pt !important}.css-1b3lipl::before{width:4px !important;left:1.95em !important;background-color:#3a2368 !important}

.about__content{height:100vh;display:block;color:#fff;padding-top:96px;background:#271747 url(/static/media/bg2.8b27b8b0.svg);background-position:bottom;background-repeat:no-repeat;background-size:cover}.information__title{font-weight:bold;font-size:28pt}.information__description{margin-top:12px;font-size:14pt;line-height:20pt}.information__checkout{display:block;font-size:14pt;margin-top:2px}.information__details{margin-top:-8px}.checkout__link{color:#eee;font-weight:bold}.checkout__link:hover{color:#e594ab;text-decoration:none}@media (min-width: 992px){.about__content{padding-top:80px}.information__title{font-size:36pt}.information__description{font-size:14pt;line-height:26pt}.information__checkout{font-size:16pt}.information__details{margin-top:50px}}

.contact__content{height:100vh;display:block;color:#fff;padding-top:96px;background:#271747 url(/static/media/bg.c1e44f0c.svg);background-position:bottom;background-repeat:no-repeat;background-size:cover}.contact__title{color:#eee;font-weight:bold;font-size:28pt}.contact__reveal{cursor:pointer;display:inline-block;font-weight:bold;font-size:22pt}.contact__reveal:hover{color:#e594ab}.contact__address{color:#eee;font-weight:bold;font-size:22pt}.contact__toast{position:absolute;bottom:0;right:10;z-index:2000;background:#eee}@media (min-width: 992px){.contact__content{padding-top:160px}}@media (min-width: 992px){.contact__title{font-size:36pt}}

