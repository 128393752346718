@import 'config';

/* react-timeline-scribble related */
.css-1psltl0 {
    margin-top: -1em !important;
}
.css-vcfpfv {
    background: $punkPink !important;
    outline: 8px solid $lightPurple !important;
    transform: rotate(0) !important;
    border-radius: 5px;
    -moz-outline-radius: 25px;
}
.css-692fgp {
    color: $punkPink !important;
    font-size: 12pt !important;
    font-weight: bold !important;
    background: transparent !important;
    padding: 0.25em 0em !important;
}
.css-1b3lipl {
    max-width: 150%;
}
.css-1jv1kel > h3 {
    font-size: 14pt !important;
}
.css-1jv1kel > h4 {
    font-size: 22pt !important;
}
.css-7mkrez {
    margin-top: -20px !important;
    font-size: 14pt !important;
}
.css-1b3lipl::before  {
    width: 4px !important;
    left: 1.95em !important;
    background-color: $lightPurple !important;
}