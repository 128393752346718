@import 'config';

.about { 
    &__content {
        height: 100vh;
        display: block;
        color: $white;
        padding-top: 96px;
        background: $darkPurple url("../assets/bg2.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.information {

    &__title {
        font-weight: bold;
        font-size: 28pt;
    }

    &__description {
        margin-top: 12px;
        font-size: 14pt;
        line-height: 20pt;
    }

    &__checkout {
        display: block;
        font-size: 14pt;
        margin-top: 2px;
    }

    &__details {
        margin-top: -8px;
    }
}

.checkout__link {
    color: $creamWhite;
    font-weight: bold;

    &:hover {
        color: $punkPink;
        text-decoration: none;
    }
}

// extra-large
@media (min-width: 992px) {
    .about { 
        &__content {
            padding-top: 80px;
        }
    }

    .information {
        &__title {
            font-size: 36pt;
        }

        &__description {
            font-size: 14pt;
            line-height: 26pt;
        }

        &__checkout {
            font-size: 16pt;
        }

        &__details {
            margin-top: 50px;
        }
    }
}