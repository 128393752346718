@import 'config';

.home {
    background: $darkPurple;

    &__hero {
        display: block;
        scroll-snap-align: start;
        height: 100vh;
        overflow: hidden;
        color: $white;
        background-image: url("../assets/bg2.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 74px;
    }
}

.hero {
    &__greeting {
        margin:0;
        font-size: 32pt;
        font-weight: bold;
    }

    &__myname {
        font-size: 40pt;
        font-weight: bold;
        border-bottom: 6px solid $punkPink;
    }

    &__caption {
        font-size: 20pt;
    }

    &__caption > span {
        display: block;
        font-weight: bold;
    }

    &__caption > span {
        display: block;
        font-weight: bold;
    }

    &__scrolldown {
        display: inline-block;
        color: $white;
        font-size: 14pt;
    
        &:hover {
            color: $punkPink;
            text-decoration: none;
        }
    }
}

.home__work {
    display: block;
    background: $creamWhite;
    color: $darkPurple;
    padding-top: 80px;
}

.work {
    &__title {
        margin: 0;
        text-align: center;
        font-size: 48pt;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__header {
        font-size: 32pt;
        padding: 32px 0px 16px;
    }

    &__description {
        margin: 0;
        font-size: 16pt;
        line-height: 28pt;
    }
}

// large
@media (min-width: 992px) {
    .home__hero {
        padding-top: 64px;
    }

    .hero {
        &__greeting {
            font-size: 58pt;
        }

        &__myname {
            font-size: 96pt;
        }

        &__caption {
            font-size: 24pt;
        }

        &__scrolldown {
            font-size: 16pt;
        }
    }

    .work {
        &__title {
            padding-bottom: 16px;
        }
    }
}

// extra-large
@media (min-width: 1200px) {
    .home__hero{
        padding-top: 64px;
    }

    .hero {
        &__greeting {
            font-size: 58pt;
        }

        &__myname {
            font-size: 104pt;
        }

        &__caption {
            font-size: 26pt;
        }

        &__scrolldown {
            font-size: 18pt;
        }
    }

    .work {
        &__title {
            padding-bottom: 32px;
        }
    }
}