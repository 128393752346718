@import 'config';

.resume {
    &__content {
        display: block;
        color: $white;
        padding-top: 96px;
        background: $darkPurple;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.experiences {
    &__title {
        color: $creamWhite;
        font-weight: bold;
        font-size: 28pt;
    }
    &__download {
        display: inline-block;
        color: $creamWhite;
        font-weight: bold;
        font-size: 14pt;
        &:hover {
            color: $punkPink;
            text-decoration: none;
        }
    }
    &__skills {
        margin-top: 24px;
        color: $creamWhite;
        font-weight: bold;
        font-size: 22pt;
    }
    &__experience {
        margin-top: 16px;
        margin-bottom: -16px;
        color: $creamWhite;
        font-weight: bold;
        font-size: 22pt;
    }
}
// extra-large
@media (min-width: 992px) {
    .resume {
        &__content {
            padding-top: 160px;
        }
    }
}

// extra-large
@media (min-width: 992px) {
    .experiences {
        &__title {
            font-size: 36pt;
        }
    }
}