@import 'config';

.contact {
    &__content {
        height: 100vh;
        display: block;
        color: $white;
        padding-top: 96px;
        background: $darkPurple url("../assets/bg.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__title {
        color: $creamWhite;
        font-weight: bold;
        font-size: 28pt;
    }
    &__reveal {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        font-size: 22pt;

        &:hover {
            color: $punkPink;
        }
    }
    &__address {
        color: $creamWhite;
        font-weight: bold;
        font-size: 22pt;
    }
    &__toast {
        position: absolute;
        bottom: 0;
        right: 10;
        z-index: 2000;
        background: $creamWhite;
    }
}

// large
@media (min-width: 992px) {
    .contact { 
        &__content {
            padding-top: 160px;
        }
    }
}

// extra-large
@media (min-width: 992px) {
    .contact {
        &__title {
            font-size: 36pt;
        }
    }
}