@import 'config';

.navigation {
    margin-left: auto;

    &__link {
        color: $white;
        padding: 4px 16px;
    
        &--active {
            font-weight: bold;
        }

        &:hover {
            color: $punkPink;
            text-decoration: none;
        }
    }
}

/*
    Full credits for this goes to Codeply-er @
    https://stackoverflow.com/questions/42586729/bootstrap-4-change-hamburger-toggler-color
*/
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler.navbar-toggler {
    border-color: $creamWhite;
}
