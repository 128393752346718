// external imports
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

// fonts-related variables
$fontLato: 'Lato', 'Arial', sans-serif;

// color-related variables
$white: #ffffff; // ededed
$black: #000000;
$creamWhite: #eeeeee;
$lightPurple: #3a2368;
$darkPurple: #271747;
$punkPink: #e594ab; // d34a71
$roseRed: #bf3232;